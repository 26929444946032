<template>
    <div>
            <b-alert variant="light" show class="mb-0">
                <input class="file-upload-input app-local-cursor-auto" type="file" ref="imageUploader" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @click.prevent v-on:change="addFile($event)"/>
                <div class="d-flex w-100 justify-content-between" >
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon variant="secondary" icon="cloud-download-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>
                        
                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-select-data-source',215)}} </span><br/>  
                            <span class="text-small">{{trans('sh-drag-or-select-csv-xlsx',215)}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-2" style="min-width:160px">
                        <app-button type="secondary-grey" :loading="file_uploading">{{trans('sh-select-document',221)}}
                            <input class="file-upload-input" type="file" ref="imageUploader" v-bind:multiple="import_type == 1" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-on:change="addFile($event)"/>
                        </app-button>
                    </div>
                </div>                
            </b-alert>  

            <b-list-group>
                <b-list-group-item v-for="item in items"  v-bind:key="item.name">
                     <div>
                            <b-row >
                                <b-col cols="11">
                                    {{item.name}}
                                </b-col>
                                <b-col cols="1" class="mr-auto">
                                        <b-icon icon="x" lg @click="removeItem(item)"  font-scale="2" style="color:#066791"></b-icon>
                                </b-col>
                             </b-row>

                     </div>

                     </b-list-group-item>

                     <b-list-group-item v-for="item ,key in upload_items"  v-bind:key="item.name">
                     <div @click="showDropDown(item, key)">
                            <b-row >
                                <b-col cols="11">
                                    {{item.name}}
                                </b-col>

                                <b-col cols="1" class="mr-auto">
                                    <b-icon icon="check" lg @click="removeItem(item)" v-if="item.correct == true" font-scale="2" style="color:#066791"></b-icon>
                                    <b-icon icon="exclamation-circle" lg @click="removeItem(item)" v-else  font-scale="1.4" style="color:#066791"></b-icon>
                                </b-col>

                             </b-row>
                              <div class="mt-4 ml-4" v-if="item.show == true">
                                    <span v-if="(typeof item.additional_info != 'object') ">{{item.additional_info}}</span>
                                    <div v-else>
                                        <span class="row ml-1" v-for="sheet, key in item.additional_info" v-bind:key="sheet"> <b>Sheet {{key}}</b>: "{{sheet}}""</span>
                                    </div>
                            </div>

                     </div>

                     </b-list-group-item>
            </b-list-group>      
            <app-button type="secondary-orange" :loading="file_uploading" v-if="items.length > 0" @click="uploadFile" class="mt-4">{{trans('sh-select-document',221)}} </app-button>
    </div>
</template>
<script>

import axios from "@axios";
import * as CSV from 'csv-string';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    props: {
        import_type: { },    
    },

    components: {
    },

    watch: {
        import_type: {
            handler: function(val) {
                this.items = [];
                this.upload_items = [];
            },
        },

    },

    data() {
        return {      
            file_uploading: false,
            alertClass: new AppAlerts(),
            items: [],
            upload_items: [],
            opt: [ { value: 1, text: this.trans('client',5) }, { value: 2, text: "Person"}, {value: 2, text: "User" }],
            refresh: 0
        };
    },

    methods: {

        async addFile(e) {

            var files = e.target.files;
            if(this.import_type == 1){
                Array.from(files).forEach(file => {
                    if(!file.type.match('text/csv') && !file.type.match('application/csv.*') && !file.type.match('application/vnd.ms-excel.*') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*')) {
                        this.alertClass.openAlertWarning(this.trans('sh-not-supported-format',221)).then(res => {});
                        return;
                    }else{
                        file.show = false;
                        this.items.push(file);
                        this.upload_items = true;
                    }
                });
            }else{
                if(!files[0].type.match('text/csv') && !files[0].type.match('application/csv.*') && !files[0].type.match('application/vnd.ms-excel.*') && !files[0].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*')) {
                    this.alertClass.openAlertWarning(this.trans('sh-not-supported-format',221)).then(res => {});
                    return;
                }else{
                    files[0].show = false;
                    this.items = [];
                    this.items.push(files[0]);
                    this.upload_items = true;
                }
            }
            

            
       
        },

        showDropDown(item, key){
            if(!item.correct && this.import_type === 1){
                item.show = !item.show ;
                this.$set(this.upload_items, key, item);
                this.refresh++;
            }
            
        },


        uploadFile(){
            this.file_uploading = true;
            let form_data = new FormData();

            this.items.forEach(file =>{
                    form_data.append(file.name.replace(/\./g, '').replace(/ /g,''), file);
                }
            )
           
            form_data.append("file_list", JSON.stringify(this.items.map(x => x['name'].replace(/\./g, '').replace(/ /g,''))));

            form_data.append("import_type", this.import_type);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            
            axios
                .post("settings/importData/import", form_data, config)
                .then((res) => {
                    this.upload_items = this.items.map(a => {
                                                            var b ={}; 
                                                            b.name = a.name; 
                                                            b.correct = true; 
                                                            return b
                                                        });
                    this.items = [];
                    if(typeof  res.data == 'object' && this.import_type == 2){
                        var alert_string = '<h3> Duplicate Kvk </h3>'
                        if(res.data.duplicates[0].kvk_number == null ){
                            alert_string = this.trans('settings-import-failed',208)
                        }else{
                             res.data.forEach((item)=>{
                                alert_string  += '<b>Kvk:' + item.kvk_number +' in clients: </b> <br>' + item.clients + '<br><br>'
                            })
                        }
                       

                        this.upload_items[0].correct = false;
                        this.alertClass.openAlertWarning(alert_string).then(res => {});
                    }else if(typeof res.data == 'object'){
                        Object.entries(res.data).forEach(element => {
                            var item = this.upload_items.find(item => item.name == element[0]);
                            item.correct = false;
                            item.additional_info = element[1];
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.alertClass.openAlertWarning(this.trans('settings-import-failed',208)).then(res => {});
                })
                .finally(() => {
                    this.file_uploading = false; 
                })
        },

        removeItem(item){
            this.items = this.items.filter(el => el.name != item.name);
        },
        
        removeImportData(){
            this.items = [];
        },

        
    }
   
};
</script>
<style scoped>

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}


.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.file-upload-input {
    position: absolute;
    left:0;
    top:0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.app-local-cursor-auto {
    cursor: auto;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

</style>
