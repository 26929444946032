<template>
    <div>
        <b-card class="mb-2">       
            <b-row class="app-card-header">                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div> Import to H1 </div>
                    </div>
                </b-col>
            
                <b-col cols="auto" class="pl-0" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': import_type !== 1, 'app-list-button-selected':  import_type === 1 }" @click="setListType(1)">Clients</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': import_type !== 2, 'app-list-button-selected':  import_type === 2 }" @click="setListType(2)">People</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': import_type !== 3, 'app-list-button-selected':  import_type === 3 }" @click="setListType(3)">Users</b-btn>
                    </b-button-group>                             
                </b-col>
            </b-row>            
        </b-card>
        <easyflex-import-clients v-if="import_type == 1" class="mb-2"/>
        <easyflex-import-people v-if="import_type == 2" class="mb-2"/>
        <easyflex-import-users v-if="import_type == 3"/>

    </div>
</template>

<script>
import axios from "@axios";
import EasyflexImportClients from './EasyflexImportClients.vue';
import EasyflexImportPeople from './EasyflexImportPeople.vue';
import EasyflexImportUsers from './EasyflexImportUsers.vue';
export default {
    
    components: {
        EasyflexImportClients,
        EasyflexImportPeople,
        EasyflexImportUsers
    },

    created(){
        
    },

    data(){
        return{
           // loading_person_documents: false,
           // person_documents: null
            import_type: 1,
        }
    },

    methods: {

        setListType(type) {
            this.import_type = type;
        },
       /* importPersonsDocuments(){

            this.loading_person_documents = true;

            axios
                .post('settings/easyflexImport/importEasyflexPersonsDocuments')
                .then((res) => {
                    this.person_documents = res.data.length;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_person_documents = false;
                })
        }*/
    }

}
</script>

<style>

</style>