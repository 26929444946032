<template>
   
         <b-card>
        
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div> Clients </div>
                    </div>
                </b-col>                      
 
                <b-col cols="auto" class="pl-0" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 3, 'app-list-button-selected':  type === 3 }" @click="setListType(3)">Import one</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 4, 'app-list-button-selected':  type === 4 }" @click="setListType(4)">Import all from office</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 5, 'app-list-button-selected':  type === 5 }" @click="setListType(5)">Import clients by ids</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">Import data from excel</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">Import budget</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 6, 'app-list-button-selected':  type === 6 }" @click="setListType(6)">Update cords</b-btn>
                    </b-button-group>                             
                </b-col>
            </b-row>
            
            <hr class="app-search-modal-separator mt-2 mb-4">
            <b-row v-if="type == 3">
                <b-col cols="2"> </b-col>
                <b-col>
                    <app-row-left-label label="1. Refresh list from EF">
                        <app-button type="secondary-grey" :loading="loading_clients" @click="refreshClientList"> Refresh </app-button>
                    </app-row-left-label>
                    <app-row-left-label label="2. Select client">
                        <app-select class="w-100" v-model="client_easyflex" type="getClientsListToImport"  validatorName="Client" validatorRules="required"/>
                    </app-row-left-label>
                    <app-row-left-label label="3. Import selected client to H1">
                        <app-button type="primary" :loading="loading_import_client" @click="importClient"> Import </app-button>  <app-button type="secondary-grey" v-if="import_status == -2" :loading="loading_remove_data" @click="removeExistingData"> Remove existing data  </app-button>
                    </app-row-left-label> 
                    <app-row-left-label v-if="import_status != 0" label=" " class="mt-1">
                        <b-alert variant="success" class="p-3" v-if="import_status == 1" show>
                            Client imported
                        </b-alert>
                        <b-alert variant="success" class="p-3" v-if="import_status == 2" show>
                            Planning data has been removed. Please import clients again
                        </b-alert>
                        <b-alert variant="danger" class="p-3" v-if="import_status == -1" show>
                            Error during import
                        </b-alert>
                        <b-alert variant="warning" class="p-3" v-if="import_status == -2" show>
                            Remove existing data f.e. shifts, assigments, placements, hour declarations
                        </b-alert>
                    </app-row-left-label>
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>

            <b-row v-if="type == 4">
                <b-col cols="2"> </b-col>
                <b-col>
                    <app-row-left-label label="1. Select office">
                        <app-select class="w-100" v-model="selected_office" type="getLocations"  validatorName="office" validatorRules="required"/>
                    </app-row-left-label>
                    <app-row-left-label label="2. Import selected client to H1">
                        <app-button type="primary" :loading="loading_import_client" @click="importClient"> Import </app-button>  <app-button type="secondary-grey" v-if="import_status == -2" :loading="loading_remove_data" @click="removeExistingData"> Remove existing data  </app-button>
                    </app-row-left-label> 
                    <app-row-left-label v-if="import_status != 0" label=" " class="mt-1">
                        <b-alert variant="success" class="p-3" v-if="import_status == 1" show>
                            Clients imported
                        </b-alert>
                        <b-alert variant="success" class="p-3" v-if="import_status == 2" show>
                            Planning data has been removed. Please import clients again
                        </b-alert>
                        <b-alert variant="danger" class="p-3" v-if="import_status == -1" show>
                            Error during import
                        </b-alert>
                        <b-alert variant="warning" class="p-3" v-if="import_status == -2" show>
                            Remove existing data f.e. shifts, assigments, placements, hour declarations
                        </b-alert>
                    </app-row-left-label>
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>

            <b-row v-if="type == 5">
                <b-col cols="2"> </b-col>
                <b-col>
                    <app-row-left-label label="1. Input clients ids">
                        <app-memo class="w-100" v-model="clients_easyflex"  validatorName="clients" validatorRules="required"/>
                    </app-row-left-label>
                    <app-row-left-label label="2. Import selected client to H1">
                        <app-button type="primary" :loading="loading_import_client" @click="importClient"> Import </app-button>  <app-button type="secondary-grey" v-if="import_status == -2" :loading="loading_remove_data" @click="removeExistingData"> Remove existing data  </app-button>
                    </app-row-left-label> 
                    <app-row-left-label v-if="import_status != 0" label=" " class="mt-1">
                        <b-alert variant="success" class="p-3" v-if="import_status == 1" show>
                            Clients imported
                        </b-alert>
                        <b-alert variant="success" class="p-3" v-if="import_status == 2" show>
                            Planning data has been removed. Please import clients again
                        </b-alert>
                        <b-alert variant="danger" class="p-3" v-if="import_status == -1" show>
                            Error during import
                        </b-alert>
                        <b-alert variant="warning" class="p-3" v-if="import_status == -2" show>
                            Remove existing data f.e. shifts, assigments, placements, hour declarations
                        </b-alert>
                    </app-row-left-label>
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>

            <b-row v-if="type == 6">
                <b-col cols="2"> </b-col>
                <b-col>
                    Update Cors:
                    <app-button type="primary" :loading="loading_import_client" @click="updateCords(1)"> Persons </app-button>
                    <app-button type="primary" :loading="loading_import_client" @click="updateCords(2)"> Clients </app-button> 
                    <app-button type="primary" :loading="loading_import_client" @click="updateCords(3)"> Locations </app-button> 
                    <app-button type="primary" :loading="loading_import_client" @click="updateCords(4)"> Departaments </app-button>  
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>



             <b-row v-if="type == 1 || type == 2">
                <b-col cols="2"> </b-col>
                <b-col>
                    <easyflex-import-clients-from-excel :import_type.sync="type"/>
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>
    </b-card>
                
    </div>
</template>

<script>
import axios from "@axios";
import EasyflexImportClientsFromExcel from './EasyflexImportClientsFromExcel.vue';
export default {
    
    components: {
            EasyflexImportClientsFromExcel
    },

    created(){
        
    },

    data(){
        return{
            type:3,
            client_easyflex: null,
            client: null,
            selected_office: null,
            loading_clients: false,
            loading_import_client: false,
            loading_remove_data: false,
            import_status: 0,
            clients_easyflex: ''
        }
    },

    methods: {
        setListType(type) {
            this.type = type;
        },

         refreshClientList() {
            this.import_status = 0;
            this.loading_clients = true;
            axios
                .post("settings/easyflexImport/importClientsFromEF", {
                })
                .then((res) => {
                   
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_clients = false;
                });
        },

        importClient() {
            this.import_status = 0;
            this.loading_import_client = true;
            axios
                .post("settings/easyflexImport/importClientToH1", {
                     id_easyflex: this.client_easyflex != null ? this.client_easyflex.value : null,
                     ids_easyflex: this.clients_easyflex,
                     id_office_data_location: this.selected_office != null ? this.selected_office.value : null,
                     type: this.type
                })
                .then((res) => {
                   this.import_status = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_import_client = false;
                });
        },

        updateCords(type) {
            this.import_status = 0;
            this.loading_import_client = true;
            axios
                .post("core/coordinates/updateCoordinates", {
                     type: type
                })
                .then((res) => {
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_import_client = false;
                });
        },

        removeExistingData(){
            this.import_status = 0;
            this.loading_remove_data = true;
            axios
                .post("settings/easyflexImport/removeExistingClientData", {
                     id_easyflex: this.client_easyflex != null ? this.client_easyflex.value : null,
                     id_office_data_location: this.selected_office != null ? this.selected_office.value : null,
                     type: this.type
                })
                .then((res) => {
                   this.import_status = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_remove_data = false;
                });

        },

        
        
    }

}
</script>

<style>

</style>