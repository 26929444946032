<template>
   
         <b-card>
        
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div class="mb-4"> Users </div>

                    <app-row-left-label label="Match users from Azure">
                       <app-button type="primary" :loading="loading_match" @click="matchUsers"> Match users </app-button> 
                    </app-row-left-label> 
                        

                    </div>
                </b-col>                      
 
            </b-row>
            
    </b-card>
                
    </div>
</template>

<script>
import axios from "@axios";

export default {
    
    components: {
           
    },

    created(){
        
    },

    data(){
        return{
             loading_match: false,
        }
    },

    methods: {
        
        matchUsers() {
            this.import_status = 0;
            this.loading_match = true;
            axios
                .post("settings/users/matchUsers")
                .then((res) => {
                   this.import_status = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_match = false;
                });
        },

        
    }

}
</script>

<style>

</style>