<template>
    <div>
         <b-card>
        
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <!--<div> People </div>-->
                    </div>
                </b-col>                      
 
                <b-col cols="auto" class="pl-0" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">People</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">BSN</b-btn>
                    </b-button-group>                             
                </b-col>
            </b-row>
            
            <hr class="app-search-modal-separator mt-2 mb-4">

            <!----------------------------- PEOPLE  ------------------------------->

            <div v-if="type == 2">

                <app-view-table :title="'Total persons (' + total + ') Persons not imported (' + total_null + ')'" :customColumns="customColumns" :customColumnsHeaders="customHeaders" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="items" :fields="fields" :isDropdownMenu="false" class="table-selectable">
                    <template v-slot:head_selected>
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary" @change="selectAll()" style="margin-top:5px" size="md" :class="selected_all_rows ? 'custom-checkbox-checked' : ''"/>
                            </b-form-group>
                        </div>
                    </template>

                    <template v-slot:selected="cell">
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary" style="margin-top:5px" size="md" :class="cell.data.item.selected ? 'custom-checkbox-checked' : ''"/>
                            </b-form-group>
                        </div>
                    </template>
                    
                    <template v-slot:buttons>
                        <b-dropdown v-if="checked_items > 0" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                            <template #button-content>
                                {{trans('action',3)}} ({{checked_items}})                         
                            </template>

                            <b-dropdown-item @click="importSelectedPersonsToH1()">Importeren</b-dropdown-item>    

                        </b-dropdown>

                        <b-dropdown no-caret toggle-class="app-button-multi mr-2" variant="primary">
                            <template #button-content>Extra operations</template>

                            <b-dropdown-item @click="importFromEFAdditionalData(1)">Import placements</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(2)">Import contracts</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(3)">Import documents</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(4)">Custom 4</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(5)">Custom 5</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(6)">Custom 6</b-dropdown-item>
                            <b-dropdown-item @click="importFromEFAdditionalData(7)">Remove people</b-dropdown-item>

                        </b-dropdown>

                        <app-button type="primary" @click="importFromEF(1)">Import van HNU</app-button>
                        <app-button type="primary" @click="importFromEF(2)">Import van HNB</app-button>
                        <app-button type="primary" @click="importZZPContracts()">Import ZZP contracts</app-button>
                    </template>
                </app-view-table>

            </div>

             <!--<b-row v-if="type == 2">
                <b-col cols="2"> </b-col>
                <b-col>
                    <app-row-left-label label="1. Select office">
                        <app-select class="w-100" v-model="selected_office" type="getLocations"  validatorName="office" validatorRules="required"/>
                    </app-row-left-label>
                    <app-row-left-label label="2. Import people from selected office to H1">
                        <app-button type="primary" :loading="loading_import_people" @click="importPeople"> Import </app-button>  <app-button type="secondary-grey" v-if="import_status == -2" :loading="loading_remove_data" @click="removeExistingData"> Remove existing data  </app-button>
                    </app-row-left-label> 
                    <app-row-left-label v-if="import_status != 0" label=" " class="mt-1">
                        <b-alert variant="success" class="p-3" v-if="import_status == 1" show>
                            Persons imported
                        </b-alert>
                        <b-alert variant="success" class="p-3" v-if="import_status == 2" show>
                            Planning data has been removed. Please import people again
                        </b-alert>
                        <b-alert variant="danger" class="p-3" v-if="import_status == -1" show>
                            Error during import
                        </b-alert>
                        <b-alert variant="warning" class="p-3" v-if="import_status == -2" show>
                            Remove existing data f.e. shifts, assigments, placements, hour declarations
                        </b-alert>
                        <b-alert variant="warning" class="p-3" v-if="import_status == -3" show>
                            People not found in EF or there is a problem with API connection to EF
                        </b-alert>
                    </app-row-left-label>
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>-->

            <!-------------------- BSN ------------------------------>

             <b-row v-if="type == 1">
                <b-col cols="2"> </b-col>
                <b-col>
                    <easyflex-import-people-bsn-from-excel />
                </b-col>
                <b-col cols="2"> </b-col>
            </b-row>
            
    </b-card>
                
    </div>
</template>

<script>
import axios from "@axios";
import EasyflexImportPeopleBsnFromExcel from './EasyflexImportPeopleBsnFromExcel';

export default {
    
    components: {
            EasyflexImportPeopleBsnFromExcel
    },

    created(){
        
    },

    mounted() {
            this.onCreateThis();
    },

    data(){
        return{
            customColumns: ["selected"],
            customHeaders: ['selected'],
            type:2,
            selected_office: null,
            loading_import_people: false,
            import_status: 0,

            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "person_name", label: 'Naam', thStyle: { width: "100%" }, visible: true, sortable: false,},
                { key: "registration_number", label: 'Registratie nummer', thStyle: { "min-width": "140px", "max-width": "140px" }, visible: true, sortable: true,},
                { key: "ef_source", label: 'Bron', thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, sortable: true,},
                { key: "location_name", label: 'Vestiging', thStyle: { "min-width": "140px", "max-width": "140px" }, visible: true, sortable: true,},
                { key: "imported_ef_date", label: 'Datum geïmporteerd', thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true, sortable: true,} 
            ],
            items: [],
            select_all: '0',
            refreshCheck: 0,
            selected_all_rows: false,
            checked_items: 0,
            total: 0,
            total_null: 0
        }
    },

    methods: {
        setListType(type) {
            this.type = type;
            
            if(type == 2){
                this.getPersonEFList();
            }
            
        },

        importPeople() {
            this.import_status = 0;
            this.loading_import_people = true;
            axios
                .post("settings/easyflexImport/importPersonsFromEF", {
                     id_office_data_location: this.selected_office.value
                })
                .then((res) => {
                   this.import_status = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_import_people = false;
                });
        },

        removeExistingData(){
            this.import_status = 0;
            this.loading_remove_data = true;
            axios
                .post("settings/easyflexImport/removeExistingPeopleData", {
                    id_office_data_location: this.selected_office != null ? this.selected_office.value : null
                })
                .then((res) => {
                this.import_status = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_remove_data = false;
                });

        },

        getPersonEFList: function(){
            this.loading_list = true;
            axios
                .post("settings/easyflexImport/getImportedPersonsFromEFAll", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.total = res.data.total;
                    this.total_null = res.data.total_null;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                });
        },

        selectAll(){
            
            if(this.selected_all_rows == true){
                this.items.list.forEach(element => {
                    element.selected = true;
                });

                

            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.checked_items = 0;
            }

            this.select();

            this.refreshCheck++;
        },

        select(){
            let i = 0;
            this.items.list.forEach(element => {
                if(element.selected == true){
                    i++;
                }
            });

            this.checked_items = i;

            if(i == this.items.list.length){
                this.selected_all_rows = true
            }else{
                this.selected_all_rows = false 
            }
        },

        importSelectedPersonsToH1(){

            this.loading_list = true;

            let person_items = [];

            this.items.list.forEach(element => {
                if(element.selected == true){
                    person_items.push({registration_number: element.registration_number,
                                        id_source: element.id_source,
                                        id_person_data_main: element.id_person_data_main
                                        });
                }
            });

            axios
                 .post("settings/easyflexImport/importSelectedPersonsToH1 ", {
                     filters: JSON.stringify(this.filters),
                     items: JSON.stringify(person_items)
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.total = res.data.total;
                    this.total_null = res.data.total_null;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                })

        },

        importFromEF(type){
            this.loading_list = true;

            axios
                 .post("settings/easyflexImport/importPersonsAll", {
                     filters: JSON.stringify(this.filters),
                     type: type
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.total = res.data.total;
                    this.total_null = res.data.total_null;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                })

        },

        importFromEFAdditionalData(type){

            this.loading_list = true;

            axios
                 .post("settings/easyflexImport/importAdditionalDataPersonsAll", {
                     filters: JSON.stringify(this.filters),
                     type: type
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.total = res.data.total;
                    this.total_null = res.data.total_null;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                })

        },

        importZZPContracts(){
            this.loading_list = true;

            axios
                 .post("settings/easyflexImport/importZZPContracts", {
                     filters: JSON.stringify(this.filters)
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.total = res.data.total;
                    this.total_null = res.data.total_null;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                });
        },

        onCreateThis() { 

            this.getPersonEFList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getPersonEFList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getPersonEFList();
                } 
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getPersonEFList();
                }   
            });
        },
        
    }

}
</script>

<style>
.btn.dropdown-toggle{
    font-size: 0.75rem !important;
}
</style>